import React from "react"

import {
  ErrorButton,
  ErrorCode,
  ErrorMessage,
  ErrorPageContainer,
  ErrorPageWrapper,
} from "./styles"

const ErrorPage = ({ message, code, button }) => {
  return (
    <ErrorPageContainer>
      <ErrorPageWrapper>
        <ErrorCode>{code}</ErrorCode>
        <ErrorMessage>{message}</ErrorMessage>
        <ErrorButton to="/">{button}</ErrorButton>
      </ErrorPageWrapper>
    </ErrorPageContainer>
  )
}

export { ErrorPage }
