import React from "react"

import SEO from "../components/seo"
import { ErrorPage } from "../shared/ErrorPage/errorPage"

const data_error = {
  button: "strona główna",
  code: "404",
  message: "Wybrana strona nie istnieje!",
}

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ErrorPage
      button={data_error.button}
      code={data_error.code}
      message={data_error.message}
    />
  </>
)

export default NotFoundPage
