import styled from "styled-components"
import { Link } from "gatsby"
import MainBg from "../../assets/images/main_bg.png"
export const ErrorButton = styled(Link)`
  padding: 10px 36px;
  background-color: ${props => props.theme.color.secondary};
  font-size: 16px;
  line-height: 1.4;
  display: inline-block;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  transition: 0.4s all ease;
  border: 2px solid ${props => props.theme.color.secondary};
  &:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 1441px) {
    font-size: 12px;
    padding: 7px 28px;
  }
`

export const ErrorCode = styled.div`
  font-size: 250px;
  color: #fff;
  font-weight: 900;
  @media screen and (max-width: 1441px) {
    font-size: 120px;
  }
`

export const ErrorPageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-image: url(${MainBg});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  background-color: ${props => props.theme.color.primary};
  @media screen and (max-width: 769px) {
    align-items: center;
    background-size: cover;
  }
`
export const ErrorMessage = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  color: ${props => props.theme.color.white};
  @media screen and (max-width: 1441px) {
    font-size: 14px;
  }
`

export const ErrorPageWrapper = styled.div`
  width: 45%;
  min-height: 400px;
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    width: 100%;
  }
`
